<template>
	<div class="box">
		<slot />
	</div>
</template>

<style scoped>
.box {
	background-color: #fff;
	border-radius: 5px;
	padding: 0.8em 1.2em;
}

@media (prefers-color-scheme: dark) {
	.box {
		background-color: #404d72;
	}
}
</style>
